<template>
  <div id="home">
    <banner/>
    <v-responsive class="mx-auto" :max-width="$vuetify.breakpoint.mobile?'100%':'70%'">
      <feed />
    </v-responsive>

    <base-main-title :title="'السوشيال ميديا'" />
    <parallax />

    <v-responsive class="mx-auto" :max-width="$vuetify.breakpoint.mobile?'100%':'70%'">
      <about />
    </v-responsive>

    
    <contact />

    <v-responsive class="mx-auto" :max-width="$vuetify.breakpoint.mobile?'100%':'70%'">
    <latest />
  </v-responsive>

  </div>

</template>

<script>

export default {
  name: 'Home',
  components: {
    Banner: () => import('@/components/home/Banner'),
    About: () => import('@/components/home/About'),
    Feed: () => import('@/components/Feed'),
    Parallax: () => import('@/components/home/Parallax'),
    Latest: () => import('@/components/home/Latest'),
    Contact: () => import('@/components/home/Contact'),
   
  },
}
</script>
