<template>
<v-responsive class="mx-auto" :max-width="$vuetify.breakpoint.mobile?'100%':'70%'">
    <v-container>
      <v-row v-if="!loading">
        <v-col cols="12">
          <v-toolbar flat>
            <v-toolbar-title>الفديوهات</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu transition="slide-x-transition" bottom right max-height="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" color="primary" class="ma-2 white--text">
                  <v-icon left dark>
                    mdi-format-list-bulleted-square
                  </v-icon>
                  الاقسام
                </v-btn>
              </template>

              <v-list>
                <v-list-item v-for="(item, i) in tags.data" :key="i" @click="tag = item.id">
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-text-field v-model="search" append-icon="mdi-magnify" flat hide-details solo-inverted
              style="max-width: 300px" @click:append="getVideos()" v-on:keyup.enter="getVideos()" />
          </v-toolbar>
        </v-col>
      </v-row>
      <progress-circular />
      <v-row v-if="!loading">
        <video-card v-for="(video, i) in videos.data" :key="i" :size="3" :value="video" />
      </v-row>
      <v-row v-if="!loading" align="center">
        <v-col cols="3">
          <base-btn v-if="page !== 1" class="mr-0" square title="Previous page" @click="page--">
            <v-icon>mdi-chevron-right</v-icon>
          </base-btn>
        </v-col>

        <v-col v-if="videos.last_page > 1" class="text-center subheading" cols="6">
          صفحة {{ videos.current_page }} من {{ videos.last_page }}
        </v-col>

        <v-col class="text-left" cols="3">
          <base-btn v-if="videos.last_page > 1 && page < videos.last_page" class="ml-0" square title="Next page"
            @click="page++">
            <v-icon>mdi-chevron-left</v-icon>
          </base-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
// Utilities
import { mapGetters } from "vuex";
import { TAGS } from "../constants/index";
export default {
  name: "Vidoes",
  components: {
    VideoCard: () => import('@/components/VideoCard'),
  },
  data: () => ({
    page: 1,
    search: '',
    tag:TAGS.GENERAL,
    exceptTag:'',
    insideReady: false,
  }),
  created() {
    this.getVideos();
    this.getTagsByType();
  },
  computed: {
    ...mapGetters(["loading", "videos", "tags"]),
  },

  methods: {
    getVideos() {
      this.$store.dispatch("getVideos", { 'page': this.page, 'search': this.search ,'tag': this.tag,'exceptTag': this.exceptTag });
    },
    getTagsByType() {
      this.$store.dispatch("getTagsByType", this.getType(this.$route.name)
      );
    },
    handleClick(event, id) {
      let ref = 'vid' + id;
      this.$refs[ref][0][event]();
      console.log(this.$refs[ref][0])
    },
    //   stopOtherVideos(target) {

    //    console.log(target)

    //   //this.$refs[target][0].player.stopVideo();
    // },
  },
  watch: {
    page() {
      this.getVideos();
      window.scrollTo(0, 0);
    },
    tag() {
      this.page= 1;
      this.getVideos();
      window.scrollTo(0, 0)
    },
    search(val) {
      if (val == '') {
        //this.tag='';
        this.getVideos()
        window.scrollTo(0, 0)
      }
    },
  },
};
</script>
<style>
.vlt-preview button {
  display: none;
}

.img-default-size {
  transition: .3s ease-in-out;
}

.img-rotate-size {

  transition: .3s ease-in-out;
  transform: rotate(5deg) scale(1.2);

}
</style>

