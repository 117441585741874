import axios from "axios";
import { BASE_URL, APP_KEY } from "../constants/index";

const VideoModule = {
  state: {
    videos: [],
    video: {},
    videosِAfkar: [],
    videoAfkar: {},
  },
  getters: {
    videos(state) {
      return state.videos;
    },
    video(state) {
      return state.video;
    },
    videosAfkar(state) {
      return state.videosAfkar;
    },
    videoAfkar(state) {
      return state.videoAfkar;
    },
  },
  mutations: {
    setVideos(state, payload) {
      state.videos = payload;
    },
    setVideo(state, payload) {
      state.video = payload;
    },
    setVideosAfkar(state, payload) {
      state.videosAfkar = payload;
    },
    setVideoAfkar(state, payload) {
      state.videoAfkar = payload;
    },
  },

  actions: {
    // Departments
    getVideos(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
          BASE_URL +
            `/videos?page=${payload.page}&search=${payload.search}&tag=${payload.tag}&exceptTag=${payload.exceptTag}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((videos) => {
          context.commit("setLoading", false);
          context.commit("setVideos", videos);
        })
        .catch((error) => {
          context.commit("setLoading", false);
          context.commit("setError", error);
          console.log(error);
        });
    },
    // Get Video
    getVideo(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(BASE_URL + `/videos/${payload}`, {
          headers: {
            Accept: "application/json",
            APP_KEY: APP_KEY,
          },
        })

        .then((response) => response.data)
        .then((video) => {
          context.commit("setLoading", false);
          context.commit("setVideo", video);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getVideosAfkar(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(
          BASE_URL +
            `/videos?page=${payload.page}&search=${payload.search}&tag=${payload.tag}&exceptTag=${payload.exceptTag}`,
          {
            headers: {
              Accept: "application/json",
              APP_KEY: APP_KEY,
            },
          }
        )

        .then((response) => response.data)
        .then((videos) => {
          context.commit("setLoading", false);
          context.commit("setVideosAfkar", videos);
        })
        .catch((error) => {
          context.commit("setLoading", false);
          context.commit("setError", error);
          console.log(error);
        });
    },
    // Get Video
    getVideoAfkar(context, payload) {
      context.commit("setLoading", true);
      context.commit("clearError");
      axios
        .get(BASE_URL + `/videos/${payload}`, {
          headers: {
            Accept: "application/json",
            APP_KEY: APP_KEY,
          },
        })

        .then((response) => response.data)
        .then((video) => {
          context.commit("setLoading", false);
          context.commit("setVideoAfkar", video);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};

export default VideoModule;
