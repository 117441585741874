<template>
  <router-link
    class="mt-1 mr-4"
    :style="
      $vuetify.theme.dark
        ? 'text-decoration:none;' + 'color:white'
        : 'text-decoration:none;' + 'color:#424141;'
    "
    :to="{ name: to }"
    target="_blank"
  >
    {{ title }}
  </router-link>
</template>
<script>
export default {
  name: "BaseRouterLink",
  props: {
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
